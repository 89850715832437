.root {
  width: 100%;
  border-radius: var(--border-radius-elevation-1);
  border: 1px solid var(--color-bd-elevation-1);
  /* TODO: --bg-elevation-1 doesn't exists. Define it */
  background: var(--bg-elevation-1, #fbfcfd);
}

.header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 22px 40px;
  align-items: flex-start;
  border-bottom: 1px solid var(--color-bd-elevation-1);
  background: rgba(255, 255, 255, 0.45);
}

@container (min-width: 700px) {
  .header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.username {
  display: flex;
  align-items: center;
  gap: 8px;
}

.thread {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;
  padding: 0px 40px var(--inner-spacing-5) 40px;
}

.promptForm {
  display: flex;
  align-items: flex-start;
  margin: var(--outer-spacing-4) 0 0 0;
  padding: 0 var(--inner-spacing-5) var(--inner-spacing-5)
    var(--inner-spacing-5);
  gap: var(--outer-spacing-3);
}
