.root {
  display: flex;
  gap: 16px;
  padding: 12px 0;
}

@container (min-width: 700px) {
  .root {
    padding: 24px 0;
  }
}

.root[data-assistant="false"] {
  flex-direction: row-reverse;
}

.root[data-assistant="false"] .sentTime {
  text-align: right;
}

.sentTime {
  margin: 0 0 8px;
  /* TODO: --type-caption doesn't exists. Define it */
  font-size: var(--type-caption, 12.247px);
  /* TODO: --type-caption-lineheight doesn't exists. Define it */
  line-height: var(--type-caption-lineheight, 17.25px);
}
